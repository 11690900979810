.app-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  min-height: 100vh;
  max-width: 800px;
  margin: auto;
  color: #000;
  padding-bottom: 5rem;
  position: relative;
  z-index: 2;
}

.logo-container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

input {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 1.25rem;
  width: 100%;
  color: #000;
  border: 1px solid #0000004c;
  min-height: 54px;
}

h1 {
  font-size: 4rem;
  margin-bottom: 0;
  line-height: 1.2;
}

p {
  font-size: 2rem;
  margin-bottom: 2rem;
  opacity: 0.5;
  width: 100%;
  margin-top: 1rem;
}

a {
  color: #4D9EE8;
}

.button {
  min-height: 54px;
  background-color: #4D9EE8;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  user-select: none;
  font-size: 1.25rem;
  margin: 0.5rem;
  border: none;
  transition: transform 0.2s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  min-width: fit-content;
}

.button:hover {
  transform: scale(1.025);
}

.button:hover .button-content {
  opacity: 0.25;
}

.button-text {
  z-index: 2;
}

.button-content {
  position: absolute;
  z-index: 1;
  transition: opacity 0.2s ease;
  left: 0;
  width: 100%;
}

.request-access-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.button.small {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin: 0;
}

.grunge-underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url('../assets/dots.png');
  opacity: 0.3;
  pointer-events: none;
  z-index: 0;
}

.logo-container img {
  height: 48px;
}

@media (max-width: 700px) {
  .request-access-container {
    flex-direction: column;
  }
  
  .request-access-container .button {
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
  }

  .logo-container img {
    height: 30px;
  }
  
  .app-content {
    justify-content: flex-start;
  }

  .hotkey {
    display: none;
  }
}

.login-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.login-button a {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid #000;
  color: #000;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  opacity: 0.5;
  font-size: 0.85rem;
}

.login-button a:hover {
  opacity: 1;
}